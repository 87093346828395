<template>
  <section v-if="errored" class="memoriam-tv--loading">
    <p v-html="txtConnectionError"></p>
  </section>
  <section v-else-if="invalid" class="memoriam-tv--invalid">
    <p>
      Embed is verkeerd geconfigureerd. Kijk op
      <a href="https://info.memoriam.tv/embedding">
        https://info.memoriam.tv/embed
      </a>
      voor documentatie.
    </p>
  </section>
  <section v-else-if="empty()" class="memoriam-tv--empty">
    <p v-if="loading" v-html="txtLoading"></p>
    <p v-else v-html="txtEmpty"></p>
  </section>
  <section v-else>
    <div v-for="(broadcast, idx) in broadcasts" :key="broadcast.id">
      <h2 v-if="showHeader(idx, broadcasts, broadcast)">
        {{ headerDate(broadcast.attributes.start_at) }}
      </h2>
      <a
        v-bind:href="landingPageUrl(broadcast.attributes.landing_page_url)"
        target="_blank"
        class="memoriam-tv--link"
      >
        <div class="memoriam-tv--player">
          <icon-player />
        </div>
        <div class="memoriam-tv--start_at">
          {{ dateAndTime(broadcast.attributes.start_at) }}
        </div>
        <div class="memoriam-tv--use_unlock_code">
          <icon-private v-if="broadcast.attributes.use_unlock_code" />
        </div>
        <div class="memoriam-tv--name">{{ broadcast.attributes.name }}</div>
      </a>
    </div>
  </section>
</template>

<style lang="scss">
.memoriam-tv--h2 {
  border-bottom: 1px solid grey;
}
.memoriam-tv--link {
  display: block;
  margin-bottom: 5px;
  text-decoration: none;
  color: black;
  padding: 5px;
  &:visited,
  &:active {
    color: black;
  }
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.1);
  }
}
.memoriam-tv--player,
.memoriam-tv--state,
.memoriam-tv--use_unlock_code,
.memoriam-tv--start_at,
.memoriam-tv--name {
  display: inline-block;
  height: 30px;
  line-height: 30px;
}
.memoriam-tv--state,
.memoriam-tv--use_unlock_code {
  width: 30px;
}
.memoriam-tv--start_at {
  width: 60px;
  text-align: center;
}
.memoriam-tv--name {
  margin-left: 5px;
}
</style>

<script>
import axios from "axios";
import IconPlayer from "./icons/player.vue";
import IconPrivate from "./icons/private.vue";

const months = [
  "januari",
  "februari",
  "maart",
  "april",
  "mei",
  "juni",
  "juli",
  "augustus",
  "september",
  "oktober",
  "november",
  "december",
];
const day_of_week = [
  "Zondag",
  "Maandag",
  "Dinsdag",
  "Woensdag",
  "Donderdag",
  "Vrijdag",
  "Zaterdag",
];
const api_endpoint = process.env.VUE_APP_API_ENDPOINT;

export default {
  components: { IconPlayer, IconPrivate },
  data() {
    return {
      broadcasts: [],
      loading: true,
      errored: false,
      invalid: false,
    };
  },
  props: {
    client_reference: { type: String, required: true },
    alternativeDomainName: { type: String, required: false },
    txtConnectionError: { type: String, required: true },
    txtLoading: { type: String, required: true },
    txtEmpty: { type: String, required: true },
  },
  methods: {
    showHeader(idx, broadcasts, broadcast) {
      if (idx === 0) {
        return true;
      }
      const prev_date = new Date(broadcasts[idx - 1].attributes.start_at);
      const next_date = new Date(broadcast.attributes.start_at);
      return (
        prev_date.getFullYear() != next_date.getFullYear() ||
        prev_date.getMonth() != next_date.getMonth() ||
        prev_date.getDate() != next_date.getDate()
      );
    },
    dateAndTime(date_str) {
      const date = new Date(date_str);
      return this.pad(date.getHours()) + ":" + this.pad(date.getMinutes());
    },
    headerDate(date_str) {
      const date = new Date(date_str);
      return (
        day_of_week[date.getDay()] +
        " " +
        date.getDate() +
        " " +
        months[date.getMonth()] +
        " " +
        date.getFullYear()
      );
    },
    pad(n) {
      return n < 10 ? "0" + n : n;
    },
    empty() {
      return this.broadcasts.length == 0;
    },
    landingPageUrl(newVal) {
      if (this.alternativeDomainName) {
        return newVal.replace("start.memoriam.tv", this.alternativeDomainName);
      }
      return newVal;
    },
  },
  mounted() {
    if (this.client_reference) {
      axios
        .get(api_endpoint + "/" + this.client_reference)
        .then((response) => {
          if (response.data.included) {
            this.broadcasts = response.data.included;
          }
        })
        .catch((error) => {
          console.error(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    } else {
      console.error(api_endpoint, this.client_reference);
      this.invalid = true;
    }
  },
};
</script>
